import React from 'react';
import { Link } from 'gatsby';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Image } from 'components';
import planet from 'images/four-o-four/planet.png';
import moon from 'images/four-o-four/moon.png';
import { ReactComponent as Toggle } from 'images/four-o-four/toggle.svg';
import { ReactComponent as PurpleToggle } from 'images/four-o-four/purple-toggle.svg';
import { ReactComponent as RedToggle } from 'images/four-o-four/red-toggle.svg';
import { ReactComponent as YelowToggle } from 'images/four-o-four/yellow-toggle.svg';
import { ReactComponent as Stars } from 'images/four-o-four/stars.svg';
import * as styles from './404.module.scss';

const planetImage = {
  url: planet,
};

const moonImage = {
  url: moon,
};

const seo = {
  title: '404',
  desc: 'LaunchDarkly provides simple, scalable feature flag & toggle management (feature management) for the modern enterprise. Eliminate risk, deliver value.',
};

const FourOhFour = ({ location }) => (
  <Layout noTopBg noBottomBg location={location} seo={seo}>
    <div className={`container ${styles.container}`}>
      <div className={styles.content}>
        <h1 className={styles.gradientText}>Lost in space</h1>
        <p>
          404 error. Sorry, but the page you're <br />
          looking for doesn't exist.
        </p>
        <Link className="button" to="/">
          Return home
        </Link>
      </div>
      <div className={styles.images}>
        <Stars className={styles.stars} />
        <Image image={planetImage} className={styles.planet} />
        <YelowToggle className={styles.yellowToggle} />
        <PurpleToggle className={styles.purpleToggle} />
        <RedToggle className={styles.redToggle} />
        <div className={styles.moonToggle}>
          <Image image={moonImage} className={styles.moon} />
          <Toggle className={styles.toggle} />
        </div>
      </div>
    </div>
  </Layout>
);

export default withPrismicUnpublishedPreview(FourOhFour);
